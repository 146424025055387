import { useRouteAndQueryParams } from '@april9/stack9-react';
import { JsonInput } from '@april9/stack9-ui';
import { Record } from 'runtypes';

import BackButton from '../components/BackButton';
import { useOsVehicle } from '../hooks/inventory/vehicle';

export const VehicleDetailPage = () => {
  const {
    routeParams: { vin },
  } = useRouteAndQueryParams();

  const { vehicle } = useOsVehicle(vin);

  return (
    <div className="max-w-screen-lg mx-auto py-6 px-12">
      <div className="flex items-center gap-x-4 my-4">
        <BackButton />

        <h1 className="font-bold text-2xl grow text-center">
          {vehicle?.metadata?.displayVehicleText || 'No vehicle found.'}
        </h1>
      </div>

      {vehicle && (
        <JsonInput height={600} schema={Record({})} value={vehicle} />
      )}
    </div>
  );
};
